import type { Bank } from '@develit-io/fubex-exchange-prisma'
import type { Country, CurrencyDeep, OurBankAccount } from '@base/types'

interface DepositWallet {
  currencyCode: string
  chainId: string
  address: string
  memo?: string
}

export const useCodes = defineStore('codes', () => {
  // const languages = useState<Language[]>(() => [])
  const banks = useState<Bank[]>('banks', () => [])
  const currencies = useState<CurrencyDeep[]>('currencies', () => [])

  // const fetchLanguages = async (force?: boolean) => {
  //   if (!force && languages.value.length)
  //     return
  //   const data = await $fetch('/api/codes/languages', { method: 'GET' })
  //   languages.value = data
  // }

  // const getLanguageByCode = (code: string) =>
  //   languages.value.find(l => l.code === code)

  const fetchBanks = async (force?: boolean) => {
    if (!force && banks.value.length)
      return
    const data = await $fetch('/api/codes/banks', { method: 'GET' })
    banks.value = data
  }

  const getBankByCode = (code: string) =>
    banks.value.find(b => b.bankCode === code)

  const getBankById = (id: number) =>
    banks.value.find(b => b.bankId === id)

  const fetchCurrencies = async (force?: boolean) => {
    if (!force && currencies.value.length)
      return
    const data = await $fetch('/api/codes/currencies', { method: 'GET' })
    currencies.value = data
  }

  const getCurrencyByCode = (code: string) => {
    currencies.value.find(c => c.code === code)
  }

  const initCodes = async (force?: boolean) => {
    if (force || !banks.value.length || !currencies.value.length)
      await Promise.all([fetchBanks(force), fetchCurrencies(force)])
  }

  const depositWallets: DepositWallet[] = [
    {
      currencyCode: 'ETH',
      chainId: 'Ethereum',
      address: '0xf608e2a0104307acb282d38bb68321728e4e6776',
    },
    {
      currencyCode: 'ETH',
      chainId: 'Arbitrum',
      address: '0x4563c6fbe134a26b3efc115f547a7a0a0e35b5c6',
    },
    {
      currencyCode: 'BTC',
      chainId: 'Bitcoin',
      address: '3MhoPk5AzmEbmTMVyaY1ZyQXCfCcZY58tz',
    },
    {
      currencyCode: 'USDT',
      chainId: 'Ethereum',
      address: '0xf608e2a0104307acb282d38bb68321728e4e6776',
    },
    {
      currencyCode: 'USDT',
      chainId: 'Tron',
      address: 'TKYzee6ihwxBy65vStw6CzhY8Qxmp8oFxv',
    },
    {
      currencyCode: 'USDT',
      chainId: 'Polygon',
      address: '0xf608e2a0104307acb282d38bb68321728e4e6776',
    },
    {
      currencyCode: 'USDT',
      chainId: 'Solana',
      address: 'GXH4FkHU8gMbSKbnc2G2ECnUpJKtHch83arJeqy2f4QH',
    },
    {
      currencyCode: 'USDT',
      chainId: 'Arbitrum',
      address: '0xf608e2a0104307acb282d38bb68321728e4e6776',
    },
    {
      currencyCode: 'ADA',
      chainId: 'Cardano',
      address: 'addr1q8ygzsx4ztmrkcaghy308v5504x2g5ylnju695halttqtrhrzr27g03klu862usxqsru794d03gzkk8n86ta34n85z0sjpf04d',
    },
    {
      currencyCode: 'DAI',
      chainId: 'Ethereum',
      address: '0xf608e2a0104307acb282d38bb68321728e4e6776',
    },
    {
      currencyCode: 'DAI',
      chainId: 'Polygon',
      address: '0xf608e2a0104307acb282d38bb68321728e4e6776',
    },
    {
      currencyCode: 'DAI',
      chainId: 'Arbitrum',
      address: '0xf608e2a0104307acb282d38bb68321728e4e6776',
    },
    {
      currencyCode: 'XRP',
      chainId: 'Ripple',
      address: 'rLHzPsX6oXkzU2qL12kHCH8G8cnZv1rBJh',
      memo: '1159840012',
    },
    {
      currencyCode: 'XMR',
      chainId: 'Monero',
      address: '87gJFBxEKLaVbF5eHA9tEcGVybrhHc8jkCtrjYgXUx8n3oKdbaowHPd5XmtT96Vp2VaYmcDQhLMRiScXF9X5Q5ddRFHzmJJ',
    },
    {
      currencyCode: 'DOT',
      chainId: 'Polkadot',
      address: '14cvyBNpTG1uTqbsc2dVV17PcngLMbbsuiFw52SfP6hnhZro',
    },
    {
      currencyCode: 'SOL',
      chainId: 'Solana',
      address: 'DJfoxmqW3bUJLAC3Qj1KViTuLgHoqR5W48xQ9EKMgHuz',
    },
    {
      currencyCode: 'MATIC',
      chainId: 'Ethereum',
      address: '0xf608e2a0104307acb282d38bb68321728e4e6776',
    },
    {
      currencyCode: 'USDC',
      chainId: 'Ethereum',
      address: '0xf608e2a0104307acb282d38bb68321728e4e6776',
    },
    {
      currencyCode: 'USDC',
      chainId: 'Tron',
      address: 'TKYzee6ihwxBy65vStw6CzhY8Qxmp8oFxv',
    },
    {
      currencyCode: 'USDC',
      chainId: 'Solana',
      address: 'DJfoxmqW3bUJLAC3Qj1KViTuLgHoqR5W48xQ9EKMgHuz',
    },
    {
      currencyCode: 'USDC',
      chainId: 'Polygon ',
      address: '0xf608e2a0104307acb282d38bb68321728e4e6776',
    },
    {
      currencyCode: 'USDC',
      chainId: 'Arbitrum',
      address: '0xf608e2a0104307acb282d38bb68321728e4e6776',
    },
    {
      currencyCode: 'WBTC',
      chainId: 'Ethereum',
      address: '0xf608e2a0104307acb282d38bb68321728e4e6776',
    },
    {
      currencyCode: 'LTC',
      chainId: 'Litecoin',
      address: 'Lfkeg7kcqsXhcLtg5XJ4ZUCALe1sQzHzQu',
    },
    {
      currencyCode: 'SHIB',
      chainId: 'Ethereum',
      address: '0xf608e2a0104307acb282d38bb68321728e4e6776',
    },
    {
      currencyCode: 'ARB',
      chainId: 'Arbitrum',
      address: '0xf608e2a0104307acb282d38bb68321728e4e6776',
    },
    {
      currencyCode: 'XLM',
      chainId: 'Stellar Lumens',
      address: 'GA5XIGA5C7QTPTWXQHY6MCJRMTRZDOSHR6EFIBNDQTCQHG262N4GGKTM',
      memo: '1712159073366057819',
    },
    {
      currencyCode: 'EURT',
      chainId: 'Ethereum',
      address: '0xf608e2a0104307acb282d38bb68321728e4e6776',
    },
    {
      currencyCode: 'UNI',
      chainId: 'Ethereum',
      address: '0xf608e2a0104307acb282d38bb68321728e4e6776',
    },
    {
      currencyCode: 'DOGE',
      chainId: 'Dogecoin',
      address: 'DCFztvSaisxW938BEn1gbLvime7th6PkZg',
    },
    {
      currencyCode: 'TRX',
      chainId: 'Tron',
      address: 'TKYzee6ihwxBy65vStw6CzhY8Qxmp8oFxv',
    },
    {
      currencyCode: 'CZKI',
      chainId: 'Polygon',
      address: '0x1d63788dD0e9146c51729F0AC5b1C731C35fC4f3',
    },
    {
      currencyCode: 'CZKI',
      chainId: 'Arbitrum',
      address: '0x1d63788dD0e9146c51729F0AC5b1C731C35fC4f3',
    },
    {
      currencyCode: 'CZKI',
      chainId: 'Avalanche',
      address: '0x1d63788dD0e9146c51729F0AC5b1C731C35fC4f3',
    },
  ]

  const supportedDepositChains = depositWallets.map(w => `${w.currencyCode}-${w.chainId}`)

  const getDepositWallet = (coinCode: string, chainCode: string) => {
    const wallet = depositWallets.find(w => w.currencyCode === coinCode && w.chainId === chainCode)
    if (!wallet)
      throw createError('No deposit wallet found')

    return wallet
  }

  const ourBanks: OurBankAccount[] = [
    // {
    //   bankName: 'FIO banka',
    //   bankAccountNumber: '2301924599/2010',
    //   iban: 'CZ0820100000002301924599',
    //   currency: 'CZK',
    // },
    {
      bankName: 'FIO banka',
      bankAccountNumber: '2401924609/2010',
      iban: 'CZ7320100000002401924609',
      currency: 'EUR',
    },
    {
      bankName: 'NEY',
      bankAccountNumber: '1686400029/2260',
      iban: 'CZ9622600000001686400029',
      currency: 'CZK',
    },
  ]

  const getOurBanksByCurrency = (currencyCode: string) => {
    return ourBanks.filter(bank => bank.currency === currencyCode)
  }

  const countries: Country[] = [
    { label: 'Afghánistán', code: 'AF' },
    { label: 'Albánie', code: 'AL' },
    { label: 'Alžírsko', code: 'DZ' },
    { label: 'Andorra', code: 'AD' },
    { label: 'Angola', code: 'AO' },
    { label: 'Antigua a Barbuda', code: 'AG' },
    { label: 'Argentina', code: 'AR' },
    { label: 'Arménie', code: 'AM' },
    { label: 'Austrálie', code: 'AU' },
    { label: 'Ázerbájdžán', code: 'AZ' },
    { label: 'Bahamy', code: 'BS' },
    { label: 'Bahrajn', code: 'BH' },
    { label: 'Bangladéš', code: 'BD' },
    { label: 'Barbados', code: 'BB' },
    { label: 'Belgie', code: 'BE' },
    { label: 'Belize', code: 'BZ' },
    { label: 'Bělorusko', code: 'BY' },
    { label: 'Benin', code: 'BJ' },
    { label: 'Bhútán', code: 'BT' },
    { label: 'Bolívie', code: 'BO' },
    { label: 'Bosna a Hercegovina', code: 'BA' },
    { label: 'Botswana', code: 'BW' },
    { label: 'Brazílie', code: 'BR' },
    { label: 'Brunej', code: 'BN' },
    { label: 'Bulharsko', code: 'BG' },
    { label: 'Burkina Faso', code: 'BF' },
    { label: 'Burundi', code: 'BI' },
    { label: 'Cookovy ostrovy', code: 'CK' },
    { label: 'Čad', code: 'TD' },
    { label: 'Černá Hora', code: 'ME' },
    { label: 'Česká republika', code: 'CZ', priority: 1 },
    { label: 'Čína', code: 'CN' },
    { label: 'Dánsko', code: 'DK' },
    { label: 'Demokratická republika Kongo', code: 'CD' },
    { label: 'Dominika', code: 'DM' },
    { label: 'Dominikánská republika', code: 'DO' },
    { label: 'Džibutsko', code: 'DJ' },
    { label: 'Egypt', code: 'EG' },
    { label: 'Ekvádor', code: 'EC' },
    { label: 'Ekvádorské vnitrozemské teritorium', code: 'EC' },
    { label: 'Eritrea', code: 'ER' },
    { label: 'Estonsko', code: 'EE' },
    { label: 'Eswatini', code: 'SZ' },
    { label: 'Etiopie', code: 'ET' },
    { label: 'Fidži', code: 'FJ' },
    { label: 'Filipíny', code: 'PH' },
    { label: 'Finsko', code: 'FI' },
    { label: 'Francie', code: 'FR' },
    { label: 'Gabon', code: 'GA' },
    { label: 'Gambie', code: 'GM' },
    { label: 'Ghana', code: 'GH' },
    { label: 'Grenada', code: 'GD' },
    { label: 'Gruzie', code: 'GE' },
    { label: 'Guatemala', code: 'GT' },
    { label: 'Guinea', code: 'GN' },
    { label: 'Guinea-Bissau', code: 'GW' },
    { label: 'Guyana', code: 'GY' },
    { label: 'Haiti', code: 'HT' },
    { label: 'Honduras', code: 'HN' },
    { label: 'Chile', code: 'CL' },
    { label: 'Chorvatsko', code: 'HR' },
    { label: 'Indie', code: 'IN' },
    { label: 'Indonésie', code: 'ID' },
    { label: 'Irák', code: 'IQ' },
    { label: 'Írán', code: 'IR' },
    { label: 'Irsko', code: 'IE' },
    { label: 'Island', code: 'IS' },
    { label: 'Itálie', code: 'IT' },
    { label: 'Izrael', code: 'IL' },
    { label: 'Jamajka', code: 'JM' },
    { label: 'Japonsko', code: 'JP' },
    { label: 'Jemen', code: 'YE' },
    { label: 'Jihoafrická republika', code: 'ZA' },
    { label: 'Jižní Korea', code: 'KR' },
    { label: 'Jižní Súdán', code: 'SS' },
    { label: 'Jordánsko', code: 'JO' },
    { label: 'Kambodža', code: 'KH' },
    { label: 'Kamerun', code: 'CM' },
    { label: 'Kanada', code: 'CA' },
    { label: 'Kapverdy', code: 'CV' },
    { label: 'Katar', code: 'QA' },
    { label: 'Kazachstán', code: 'KZ' },
    { label: 'Keňa', code: 'KE' },
    { label: 'Kiribati', code: 'KI' },
    { label: 'Kolumbie', code: 'CO' },
    { label: 'Komory', code: 'KM' },
    { label: 'Konžská republika', code: 'CG' },
    { label: 'Kostarika', code: 'CR' },
    { label: 'Kuba', code: 'CU' },
    { label: 'Kuvajt', code: 'KW' },
    { label: 'Kypr', code: 'CY' },
    { label: 'Kyrgyzstán', code: 'KG' },
    { label: 'Laos', code: 'LA' },
    { label: 'Lesotho', code: 'LS' },
    { label: 'Libanon', code: 'LB' },
    { label: 'Libérie', code: 'LR' },
    { label: 'Libye', code: 'LY' },
    { label: 'Lichtenštejnsko', code: 'LI' },
    { label: 'Litva', code: 'LT' },
    { label: 'Lotyšsko', code: 'LV' },
    { label: 'Lucembursko', code: 'LU' },
    { label: 'Madagaskar', code: 'MG' },
    { label: 'Maďarsko', code: 'HU' },
    { label: 'Makedonie', code: 'MK' },
    { label: 'Malajsie', code: 'MY' },
    { label: 'Malawi', code: 'MW' },
    { label: 'Maledivy', code: 'MV' },
    { label: 'Mali', code: 'ML' },
    { label: 'Malta', code: 'MT' },
    { label: 'Maroko', code: 'MA' },
    { label: 'Marshallovy ostrovy', code: 'MH' },
    { label: 'Mauricius', code: 'MU' },
    { label: 'Mauritánie', code: 'MR' },
    { label: 'Mexiko', code: 'MX' },
    { label: 'Mikronésie', code: 'FM' },
    { label: 'Moldavsko', code: 'MD' },
    { label: 'Monako', code: 'MC' },
    { label: 'Mongolsko', code: 'MN' },
    { label: 'Mosambik', code: 'MZ' },
    { label: 'Myanmar', code: 'MM' },
    { label: 'Namibie', code: 'NA' },
    { label: 'Nauru', code: 'NR' },
    { label: 'Německo', code: 'DE', priority: 1 },
    { label: 'Nepál', code: 'NP' },
    { label: 'Niger', code: 'NE' },
    { label: 'Nigérie', code: 'NG' },
    { label: 'Nikaragua', code: 'NI' },
    { label: 'Niue', code: 'NU' },
    { label: 'Nizozemsko', code: 'NL' },
    { label: 'Norsko', code: 'NO' },
    { label: 'Nový Zéland', code: 'NZ' },
    { label: 'Omán', code: 'OM' },
    { label: 'Pákistán', code: 'PK' },
    { label: 'Palau', code: 'PW' },
    { label: 'Palestina', code: 'PS' },
    { label: 'Panama', code: 'PA' },
    { label: 'Papua-Nová Guinea', code: 'PG' },
    { label: 'Paraguay', code: 'PY' },
    { label: 'Peru', code: 'PE' },
    { label: 'Pobřeží slonoviny', code: 'CI' },
    { label: 'Polsko', code: 'PL', priority: 1 },
    { label: 'Portugalsko', code: 'PT' },
    { label: 'Rakousko', code: 'AT' },
    { label: 'Rovníková Guinea', code: 'GQ' },
    { label: 'Rumunsko', code: 'RO' },
    { label: 'Rusko', code: 'RU' },
    { label: 'Rwanda', code: 'RW' },
    { label: 'Řecko', code: 'GR' },
    { label: 'Salvador', code: 'SV' },
    { label: 'Samoa', code: 'WS' },
    { label: 'San Marino', code: 'SM' },
    { label: 'Saúdská Arábie', code: 'SA' },
    { label: 'Senegal', code: 'SN' },
    { label: 'Severní Korea', code: 'KP' },
    { label: 'Severní Makedonie', code: 'MK' },
    { label: 'Seychely', code: 'SC' },
    { label: 'Sierra Leone', code: 'SL' },
    { label: 'Singapur', code: 'SG' },
    { label: 'Slovensko', code: 'SK', priority: 1 },
    { label: 'Slovinsko', code: 'SI' },
    { label: 'Somálsko', code: 'SO' },
    { label: 'Spojené arabské emiráty', code: 'AE' },
    { label: 'Spojené království', code: 'GB' },
    { label: 'Spojené státy americké', code: 'US' },
    { label: 'Srbsko', code: 'RS' },
    { label: 'Súdán', code: 'SD' },
    { label: 'Surinam', code: 'SR' },
    { label: 'Svatá Lucie', code: 'LC' },
    { label: 'Svatý Kryštof a Nevis', code: 'KN' },
    { label: 'Svatý Tomáš a Princův ostrov', code: 'ST' },
    { label: 'Svatý Vincenc a Grenadiny', code: 'VC' },
    { label: 'Svazijsko', code: 'SZ' },
    { label: 'Sýrie', code: 'SY' },
    { label: 'Šalomounovy ostrovy', code: 'SB' },
    { label: 'Španělsko', code: 'ES' },
    { label: 'Švédsko', code: 'SE' },
    { label: 'Švýcarsko', code: 'CH' },
    { label: 'Tádžikistán', code: 'TJ' },
    { label: 'Tanzanie', code: 'TZ' },
    { label: 'Thajsko', code: 'TH' },
    { label: 'Togo', code: 'TG' },
    { label: 'Tonga', code: 'TO' },
    { label: 'Trinidad a Tobago', code: 'TT' },
    { label: 'Tunisko', code: 'TN' },
    { label: 'Turecko', code: 'TR' },
    { label: 'Turkmenistán', code: 'TM' },
    { label: 'Tuvalu', code: 'TV' },
    { label: 'Uganda', code: 'UG' },
    { label: 'Ukrajina', code: 'UA' },
    { label: 'Uruguay', code: 'UY' },
    { label: 'Uzbekistán', code: 'UZ' },
    { label: 'Vanuatu', code: 'VU' },
    { label: 'Vatikán', code: 'VA' },
    { label: 'Venezuela', code: 'VE' },
    { label: 'Vietnam', code: 'VN' },
    { label: 'Zambie', code: 'ZM' },
    { label: 'Zimbabwe', code: 'ZW' },
  ]

  return {
    banks,
    currencies,
    initCodes,
    getCurrencyByCode,
    fetchBanks,
    getBankByCode,
    getBankById,
    fetchCurrencies,
    depositWallets,
    getDepositWallet,
    ourBanks,
    getOurBanksByCurrency,
    supportedDepositChains,
    countries,
  }
})
